import { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Divider, Drawer, Tooltip, Typography, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import { useTheme } from '@material-ui/core'
import AdminNavSection from './AdminNavSection';
import Scrollbar from '../Scrollbar';

import { IconContext } from 'react-icons'
import { BsMusicNoteBeamed, BsHeadphones,  } from 'react-icons/bs'
import { BiArrowToLeft } from 'react-icons/bi'
import { FaUserFriends } from 'react-icons/fa'
import { HiDocumentReport } from 'react-icons/hi'
import { MdHelp } from 'react-icons/md'
import { IoSettingsSharp } from 'react-icons/io5'
import { AiFillHome } from 'react-icons/ai'

const useStyles = makeStyles(theme => ({

}));



const sections = [
  {
    title: 'MENU',
    items: [
      {
        title: 'Spots Management',
        path: '/admin/spotsmanagement',
        icon: <IconContext.Provider value={{ color: '#EDA418', size: '22px' }}><BsMusicNoteBeamed /> </IconContext.Provider>,
        children: [
          {
            title: 'Categories',
            path: '/admin/spotsmanagement/categories',
          },
          {
            title: 'Manage Spots',
            path: '/admin/spotsmanagement/campaigns',
          },
          // {
          //   title: 'Manage Spots',
          //   path: '/admin/spotsmanagement/managespots'
          // },
          // {
          //   title: 'Search Spots',
          //   path: '/admin/spotsmanagement/searchspots'
          // },
          {
            title: 'Tag Requests',
            path: '/admin/spotsmanagement/tagrequests'
          },
          {
            title: 'Banner Requests',
            path: '/admin/spotsmanagement/bannerrequests'
          },
          {
            title: 'PreRoll Requests',
            path: '/admin/spotsmanagement/prerollrequests'
          },
          {
            title: 'Custom Requests',
            path: '/admin/spotsmanagement/customs'
          },
          // {
          //   title: 'Voiced/Produced',
          //   path: '/admin/spotsmanagement/voiced-produced'
          // },
          // {
          //   title: 'Digital',
          //   path: '/admin/spotsmanagement/digital'
          // },
          // {
          //   title: 'Spec Request Matrix',
          //   path: '/admin/spotsmanagement/spec-request-matrix'
          // },
          // {
          //   title: 'Vendors',
          //   path: '/admin/spotsmanagement/vendors'
          // },
        ]
      },
      {
        title: 'Client Management',
        adminOnly: true,
        path: '/admin/clientmanagement',
        icon: <IconContext.Provider value={{ color: '#EDA418', size: '22px' }}><FaUserFriends /> </IconContext.Provider>,
        children: [
          {
            title: 'Users',
            path: '/admin/clientmanagement/users'
          },
          {
            title: 'Managers',
            path: '/admin/clientmanagement/managers'
          },
          {
            title: 'Federations',
            path: '/admin/clientmanagement/federations'
          },
          {
            title: 'Markets',
            path: '/admin/clientmanagement/markets'
          },
          // {
          //   title: 'Demos',
          //   path: '/admin/clientmanagement/demos'
          // },
        ]
      },
      // {
      //   title: 'Producers',
      //   path: '/admin/producer-admin',
      //   icon: <IconContext.Provider value={{ color: '#EDA418', size: '22px' }}><BsFillMicFill /> </IconContext.Provider>,
      //   children: [
      //     {
      //       title: 'Producers',
      //       path: '/admin/producer-admin/producers'
      //     },
      //     {
      //       title: 'Producer Assignments',
      //       path: '/admin/producer-admin/producer-assignments'
      //     },
      //     {
      //       title: 'Languages',
      //       path: '/admin/producer-admin/languages'
      //     },
      //     {
      //       title: 'Dialects',
      //       path: '/admin/producer-admin/dialects'
      //     },
      //   ]
      // },
      {
        title: 'Concierge Settings',
        path: '/admin/concierge-settings',
        icon: <IconContext.Provider value={{ color: '#EDA418', size: '22px' }}><BsHeadphones /> </IconContext.Provider>,
        children: [
          // {
          //   title: 'Services',
          //   path: '/admin/concierge-settings/services'
          // },
          {
            title: 'Requests',
            path: '/admin/concierge-settings/requests'
          },
          // {
          //   title: 'Questions',
          //   path: '/admin/concierge-settings/questions'
          // },
        ]
      },
      {
        title: 'Site Settings',
        adminOnly: true,
        path: '/admin/site-settings',
        icon: <IconContext.Provider value={{ color: '#EDA418', size: '22px' }}><IoSettingsSharp /> </IconContext.Provider>,
        children: [
          {
            title: 'Demo Video',
            path: '/admin/site-settings/demovideo'
          },
          {
            title: 'Homepage Slider',
            path: '/admin/site-settings/homeslider'
          },
          {
            title: 'Custom Admin',
            path: '/admin/site-settings/customadmin'
          },
          {
            title: 'Station Formats',
            path: '/admin/site-settings/stationformats'
          },
          {
            title: 'Production Status',
            path: '/admin/site-settings/productionstatus'
          },
          {
            title: 'Site Announcement',
            path: '/admin/site-settings/site-announcement'
          },
          {
            title: 'Roles',
            path: '/admin/site-settings/roles'
          },
        ]
      },
      {
        title: 'Reports/Tracking',
        adminOnly: true,
        path: '/admin/reports',
        icon: <IconContext.Provider value={{ color: '#EDA418', size: '22px' }}><HiDocumentReport /> </IconContext.Provider>,
        children: [
          // {
          //   title: 'Simple Revenue',
          //   path: '/admin/reports/simplerevenue'
          // },
          {
            title: 'SPECbyte Revenue',
            path: '/admin/reports/specbyterevenue'
          },
          {
            title: 'Federation Reports',
            path: '/admin/reports/federationreports'
          },
          // {
          //   title: 'Tracked Items',
          //   path: '/admin/reports/trackeditems'
          // },
          // {
          //   title: 'Category Report',
          //   path: '/admin/reports/categoryreport'
          // },
          // {
          //   title: 'Assignment Queues',
          //   path: '/admin/reports/assignmentqueues'
          // },
        ]
      },
    ]
  },

];

const AdminSidebar = (props) => {
  const { onMobileClose, openMobile, onCloseSidebar } = props;
  const classes = useStyles()
  const location = useLocation()
  const navigate = useNavigate()
  const { isSiteAdmin } = useAuth()
  const theme = useTheme()
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box display="flex" justifyContent="left" sx={{ p: 2, ml: 2 }}>
        <Typography variant="h6" color="text.secondary" style={{ textTransform: 'uppercase' }} >Admin Menu</Typography>
      </Box>
      <Scrollbar options={{ suppressScrollX: true }}>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <AdminNavSection
              isSiteAdmin={isSiteAdmin}
              key={section.title}
              passedkey={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 2
                }
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
      <Box display="flex" justifyContent="center" sx={{ mb: 3, pt: 2 }}>
        <Tooltip title="Home" placement="top">
          <Button component={RouterLink} to="/admin">
            <IconContext.Provider value={{ color: '#EDA418', size: '32px' }}><AiFillHome /> </IconContext.Provider>
          </Button>
        </Tooltip>
        <Tooltip title="Help" placement="top">
          <Button component={RouterLink} to="/admin/help">
            <IconContext.Provider value={{ color: '#EDA418', size: '32px' }}><MdHelp /> </IconContext.Provider>
          </Button>
        </Tooltip>
        <Tooltip title="Close Menu" placement="top">
          <Button onClick={onCloseSidebar}>
            <IconContext.Provider value={{ color: '#EDA418', size: '34px' }}><BiArrowToLeft /> </IconContext.Provider>
          </Button>
        </Tooltip>
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 65px) !important',
            top: '65px !Important',
            width: 260
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 260
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

AdminSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default AdminSidebar;
