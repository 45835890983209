import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import jwtDecode from 'jwt-decode';
import BackendAPIClient from '../lib/backendApi';
import { FilterBuilder } from '@loopback/filter';
import useSettings from '../hooks/useSettings';

const initialState = {
  isNewUser: false,
  aadIdToken: null,
  isAuthenticated: false,
  isCompanyWideUser: false,
  isMarketWideUser: false,
  isSingleMarketUser: false,
  isSiteAdmin: false,
  isInitialized: false,
  isProducer: false,
  user: null
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      isNewUser: false,
      aadIdToken: null,
      user,
      isSiteAdmin: !isAuthenticated ? false : user.roles.filter(role => (role.name == "SiteAdmin" || role.name == "admin" || role.name == "SuperAdmin")).length > 0,
      isCompanyWideUser: !isAuthenticated ? false : user.roles.filter(role => (role.name == "FederationManager" || role.name == "Company Admin" && role.name == "SiteAdmin")).length > 0,
      isMarketWideUser: !isAuthenticated ? false : user.roles.filter(role => (role.name == "FederationManager" || role.name == "MarketManager" || role.name == "Company Admin" || role.name == "SiteAdmin") || role.name == 'admin').length > 0,
      isSingleMarketUser: !isAuthenticated ? false : user.roles.filter(role => (role.name != "FederationManager" && role.name != "Company Admin" && role.name != "SiteAdmin")).length >= 0,
      isProducer: !isAuthenticated ? false : user.roles.filter(role => (role.name == "Producer" || role.name == "ProducerAdmin")).length > 0
    };
  },
  LOGIN: (state, action) => {
    const user = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      isNewUser: false,
      aadIdToken: null,
      user,
      isSiteAdmin: user.roles && user.roles.filter(role => (role.name == "SiteAdmin" || role.name == "admin" || role.name == "SuperAdmin")).length > 0,
      isCompanyWideUser: user.roles && user.roles.filter(role => (role.name == "FederationManager" || role.name == "Company Admin" && role.name == "SiteAdmin")).length > 0,
      isMarketWideUser: user.roles && user.roles.filter(role => (role.name == "FederationManager" || role.name == "MarketManager" || role.name == "Company Admin" || role.name == "SiteAdmin")).length > 0,
      isSingleMarketUser: user.roles && user.roles.filter(role => (role.name != "FederationManager" && role.name != "Company Admin" && role.name != "SiteAdmin")).length > 0,
      isProducer: user.roles && user.roles.filter(role => (role.name == "Producer" || role.name == "ProducerAdmin")).length > 0
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    isSiteAdmin: false,
    isCompanyWideUser: false,
    isMarketWideUser: false,
    isSingleMarketUser: false,
    isNewUser: false,
    isProducer: false,
    aadIdToken: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  COMPLETE_REGISTRATION: (state, action) => {
    const aadIdToken = action.payload;
    console.log('COMPLETE_REGISTRATION', aadIdToken)
    return {
      ...state,
      isNewUser: true,
      aadIdToken: aadIdToken
    };
  },
};

const reducer = (state, action) => (handlers[action.type]
  ? handlers[action.type](state, action)
  : state);

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  loginAzure: () => Promise.resolve(),
  createAzure: () => Promise.resolve(),
});

/**
 * 
 * @param {{backendApi: BackendAPIClient, children: JSX.Element}} props 
 * @returns 
 */
export const AuthProvider = (props) => {
  const { backendApi, children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const { settings, setMarket, saveSettings } = useSettings();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('token');

        if (!accessToken || !(await isValidToken(accessToken))) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          return
        }

        const user = JSON.parse(localStorage.getItem('user'));
        if(!user.roles) user.roles = [];

        if (user.exp * 1000 < Date.now()) {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
          return
        }

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (username, password) => {
    const result = await backendApi.User.login({ username, password });

    if (result.result) {
      localStorage.setItem('user', JSON.stringify(result.user))
      localStorage.setItem('token', result.token)

      if (setMarket) {
        const filter = new FilterBuilder()
        filter.include(["company", "conciergeAuthorizedMarkets"])
        const market = await backendApi.Market.findById(result.user.marketId, filter.build());
        if (market.error) {
          console.error("Error getting default market on login", market.error)
        } else {
          setMarket(market)
        }
      }

      dispatch({
        type: 'LOGIN',
        payload: result.user
      });
    } else {
      console.error(result)
    }
  };

  const loginAzure = async (id_token, setMarket) => {
    const result = await backendApi.User.loginAzure(id_token);
    console.log("loginAzure result", result)
    if (result.result) {
      if (result.message == 'new user') {
        saveSettings({ ...settings, cumulusMarkets: result.markets })
        dispatch({
          type: 'COMPLETE_REGISTRATION',
          payload: id_token
        });
      } else {
        localStorage.setItem('user', JSON.stringify(result.user))
        localStorage.setItem('token', result.token)

        if (setMarket) {
          const market = await backendApi.Market.findById(result.user.marketId);
          if (market.error) {
            console.error("Error getting default market on login", market.error)
          } else {
            setMarket(market)
          }
        }
        dispatch({
          type: 'LOGIN',
          payload: result.user
        });
      }
    } else {
      console.error('Error in loginAzure: ' + result)
    }
  }

  const createAzure = async (id_token, user, setMarket) => {
    const result = await backendApi.User.createAzure(id_token, user);
    console.log("createAzure result", result)
    if (result.result) {
      localStorage.setItem('user', JSON.stringify(result.user))
      localStorage.setItem('token', result.token)

      if (setMarket) {
        const market = await backendApi.Market.findById(result.user.marketId);
        if (market.error) {
          console.error("Error getting default market on login", market.error)
        } else {
          setMarket(market)
        }
      }
      dispatch({
        type: 'LOGIN',
        payload: result.user
      });
    } else {
      console.error('Error in createAzure: ' + result)
    }
  }

  const logout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setMarket(null)
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email, name, password) => {
    /*
    const accessToken = await authApi.register({ email, name, password });
    const user = await authApi.me(accessToken);

    localStorage.setItem('token', accessToken);
    */
    dispatch({
      type: 'REGISTER',
      payload: {
        user: null
      }
    });
  };

  const isValidToken = async accessToken => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp >= currentTime;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        register,
        loginAzure,
        createAzure
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
